import { booleanAttribute, Component, Input, OnInit } from '@angular/core';
import { CommonModule, NgClass } from '@angular/common';

type buttonVariant = 'filled' | 'outlined';
type buttonType = 'primary' | 'secondary';

@Component({
  selector: 'mlk-button',
  standalone: true,
  imports: [CommonModule, NgClass],
  templateUrl: './mlk-button.component.html',
  styleUrl: './mlk-button.component.scss',
})
export class MlkButtonComponent implements OnInit {
  @Input({ required: true }) variant!: buttonVariant;
  @Input({ required: true }) type!: buttonType;
  @Input({ transform: booleanAttribute }) disabled = false;
  @Input({ transform: booleanAttribute }) submit = false;
  @Input() additionalClasses = '';
  mlkButtonClasses: Record<string, boolean> = {};

  ngOnInit(): void {
    this.setButtonClasses();
  }

  setButtonClasses() {
    this.mlkButtonClasses = {
      'mlk-btn-blue-filled':
        this.variant === 'filled' && this.type === 'primary',
      'mlk-btn-blue-outlined':
        this.variant === 'outlined' && this.type === 'primary',
      'mlk-btn-black-filled':
        this.variant === 'filled' && this.type === 'secondary',
      'mlk-btn-black-outlined':
        this.variant === 'outlined' && this.type === 'secondary',
    };
  }
}
